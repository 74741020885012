const About = () => {
  return (
    <>
      <div className='row subcontent'>
        <p>
          When you join our pack, we want to make you feel welcome. We have put
          together this list to help you jump right in and feel prepared for our
          meetings and events. Let us know if we missed something.
        </p>
        <p>
          <div className='subcontenttitle'>PACK MEETINGS</div>
          <div className='subcontent'>
            One Wednesday a month all Dens meet together for a "Pack Meeting",
            which all Cub Scouts and their families are encouraged to attend.
            The Pack Meeting is a fun time for everyone. Dens show off the
            projects they have been working on, perform skits, show off
            something from home, and awards are presented to the Scouts. It is
            also time for the family to get information on upcoming activities
            and share in the Scouting spirit. Be sure to attend and join in the
            excitement while watching your Scout advance! Scouts are encouraged
            to wear their Class A uniform for Pack Meetings.
          </div>
        </p>
        <p>
          <div className='subcontenttitle'>DEN MEETINGS </div>
          <div className='subcontent'>
            Den Meetings are where your Scout will work on various rank specific
            adventures, learn new skills, and have great fun. While parental
            attendance is not required at Den Meetings (except for Lions and
            Tigers), it is certainly encouraged! Some Dens will require parent
            participation at specific meetings. Each Den will set their schedule
            for meetings with most trying to meet every other Sunday or at least
            twice a month. Scouts will typically wear their Class B shirts to
            Den Meetings.
          </div>
        </p>
        <p>
          <div className='subcontenttitle'>PACK EVENTS</div>
          <div className='subcontent'>
            Once a month the Pack will schedule a weekend activity for everyone
            to participate in together. This is a great chance for all of the
            Scouts to come together and share in the experience. Most of the
            events are for a single afternoon with our Fall and Spring Camp Outs
            lasting for a weekend. Check out the Pack Events section for a list
            of events we have done in the past!
          </div>
        </p>
        <p>
          <div className='subcontenttitle'>FUNDRAISING</div>
          <div className='subcontent'>
            Every year the Pack participates in the Scout Popcorn sale. The
            campaign runs from August through October, with deliveries in
            November. This is the primary fundraising activity for the Pack. All
            scouts are encouraged to participate in this event. Participants
            will receive prizes as well as other recognition. The Pack relies
            heavily on the funds generated by this fundraiser. The Pack retains
            a percentage of the proceeds from the popcorn sold and is the
            single largest source of funding for all Pack activities.
          </div>
        </p>
      </div>
    </>
  );
};

export default About;
